/* eslint-disable camelcase */
export default {
  dialogs: {
    cancel: "Cancel",
    ok: "OK",
    next: "Next",
    prev: "Prev",
    submit: "Submit",
    clear: "Clear",
    back: "Back",
    save: "Save",
    close: "Close",
    apply: "Apply",
    confirm: "Confirm",
    logout: "Logout",
    update: "Update",
  },
  theme_settings: {
    save_error: "Error saving settings",
    save_ok: "Settings saved",
  },
  common: {
    filter: "Filter",
    period: "Period",
    account: "Account",
    frontline: "Frontline",
    plan_props: "Plan properties",
    profile_props: "Profile properties",
    operation: "Operation",
    documents: "Documents",
    search: "Search",
    confirmed: "Confirmed",
    not_confirmed: "Not confirmed",
  },
  filter: {
    equals: "Equals (=)",
    not_equals: "Not equals (!=)",
    less: "Less (<)",
    less_or_equals: "Less or equals (<=)",
    greater: "Greater (>)",
    greater_or_equals: "Greater or equals (>=)",
    between: "Between",
    not_between: "Not between",
    contains: "Contains",
    not_contains: "Not contains",
  },
  retry: {
    retry_in: 'Retry in {0} seconds',
    retry_now: 'Retry now',
  },
  inputs: {
    date_select: "Select date",
    date_enter: "Enter date",
    err: {
      file_size_limit_mb: "File size limit is {0} MB",
      field_required: "This field is required",
      email_invalid: "Invalid email",
      number_invalid: "Not a number",
      password_min_length: "Password must be at least {0} characters",
      passwords_not_match: "Passwords do not match",
      password_need_spec_chars: "Password must contain special symbols",
      password_need_capital: 'Password must contain capital letters',
      date_in_future: "Date can't be in the future",
    },
  },
  "properties_custom": {
    sponsor_id: "Sponsor ID",
    password: "Password",
    password_confirm: "Password confirm",
    volumeChange: "Change",
    volumeDiff: "Difference",
  },
  "properties_p": {
    title: "Title",
    invite_code: "Invite code",
    created_at: "Created at",
    account_status_id: "Account status",
    no_sign_up: "No sign up",
    privacy: "Privacy",
    language_id: "Language",
    country_id: "Country",
    bank_account: "Bank account",
    bank_name: "Bank name",
    skype: "Skype ID",
    phone: "Phone",
    card_id: "Card ID",
    gender: "Gender",
    login: "Login",
    email: "E-mail",
    lastname: "Last name",
    firstname: "First name",
    region_id: "Region",
    birth_date: "Birth date",
    avatar_id: "Avatar",
    country_and_region: "Country/Region",
    account_id: "Account ID",
    parent_name: "Parent Name",
    sponsor_info: "Sponsor info",
  },
  "properties_m": {
  },
  "properties_s": {
    id: "ID",
    externalId: "ID",
  },
  "properties_t": {
    fullName: "Full name",
    namePlusId: "Name/ID",
    accountPlusOffset: "Account/Position",
  },
  "properties_r": {
    id: "ID",
  },
  "properties_vmtl": {  // ViewModel Transaction Log
    property: "Property",
    docType: "Doc type",
    created: "Created",
    newValue: "New value",
    oldValue: "Old value",
    context: "Context",
  },
  "properties_vmdl": {
    parent_id: "Parent ID",
    level: "Relative Level",
    genealogyParentAccountId: "G Parent Id",
    number: "Number in frontline",
    childrenCount: "Children count",
    offset: "Offset",
  },
  "properties_position": {
    tree_level: "Level",
  },
  "properties_parent-profile": {
    parent_wrap: "{0} (sponsor)",
  },
  errors: {
    1000: "Access to this account is denied.",
    2001: "Invalid email or password",
    12000: "Position not found. Probably you are not in the tree.",
  },
};
